<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}

        <router-link :to="{ name: 'branch.create' }" class="btn btn-success pull-right"
                     title="Добавить" type="button">
          <i class="fa fa-plus"></i>
        </router-link>
      </h3>
    </div>

    <div class="box-body">
      <table-items :data_search="data_search"
                   :relations="relations"
                   :relations_count="relations_count"
                   component="branch-item"
                   :columns="columns"
                   api_resource="/branches">
      </table-items>
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import TableItems from './../../components/pagination/table-items';

Vue.component('branch-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'branches',

  components: {
    TableItems,
  },

  props: {
    data_search: {
      type: String,
      required: false
    }
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Филиалы',

    columns: [
      {
        title: 'Название',
        class: null,
        style: null,
      },
      {
        title: 'Город',
        class: null,
        style: null,
      },
      {
        title: 'Контакты',
        class: null,
        style: null,
      },
      {
        title: 'Автомобилей',
        class: null,
        style: null,
      },
      {
        title: 'Обновлён',
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
    ],
    relations: [
      'city',
    ],
    relations_count: [
      'cars',
    ],
  }),

  methods: {}
}
</script>

<style scoped>

</style>